import React from 'react';
import {firebaseApp} from '../fireconfig.js';
import { getFirestore ,collection , getDocs} from "firebase/firestore"

export default function Projects({projectRef}) {

    const [projectList, setProjectList] = React.useState(null);

    const monthNumber = {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
    };

    const handleClick = (url) => {
        // Redirect to the specified URL
        window.open(url, '_blank');
    };

    React.useEffect(()=>{
        async function getProjectdata(){
            const db = getFirestore(firebaseApp);
            const projectCollectionRef = collection(db, "projects");
            const projectSnapShot = await getDocs(projectCollectionRef);

            const fieldName = 'Index';

            const sortedDocs = projectSnapShot.docs.sort((a, b) => {
                const docAValue = a.data()[fieldName];
                const docBValue = b.data()[fieldName];
              
                // Sort logic (ascending order by default)
                if (docAValue > docBValue) return -1;
                if (docAValue < docBValue) return 1;
                return 0;
            });

            setProjectList(sortedDocs);
        }
        getProjectdata();
    },[])


  return (
    <div className='projects'>
        <div className='title_projects' ref={projectRef}>
            Projects
        </div>
        {
            projectList ? 
            projectList.map((project) => (
                <div className='indiv_project'>
                    <div className='project_image'>
                        <img src={project.data().Image} alt='Company Logo' style={{width : '40vh', borderRadius : "20px", border : "1px solid black"}}/>
                    </div>
                    <div className='project_data'>
                        <div className='project_data_title'>
                            {project.data().Title}  |   <span className='project_date'>{monthNumber[project.data().Date.toDate().getMonth()+1]}  {project.data().Date.toDate().getFullYear()}</span>
                        </div> 
                        <div>
                            
                        </div>
                        <div style={{marginTop : "1vh"}}>
                            {project.data().Summary}  
                        </div>
                        <div className="projects_skills">
                            
                        </div>
                        <div className='button_grp'>
                            {
                                project.data().Demo ? 
                                    <button className='demo_btn' onClick={() => handleClick(project.data().Demo)}>
                                        Demo
                                    </button>
                                    :
                                    null
                            }
                            {
                                 project.data().Github ? 
                                    <button className='code_btn' onClick={() => handleClick(project.data().Github)}>
                                        Code
                                    </button>
                                    :
                                    null
                            }
                            <div className='projects_skills'>
                                {project.data().Skills && project.data().Skills.map((skill) => (
                                    <span key={skill} className="project_skill">
                                        {skill}
                                    </span>
                                ))}
                            </div>
                            
                        </div>
                    </div>
                </div>
            ))
            :
            null
        }
    </div>
  )
}
