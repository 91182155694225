import React from 'react'
import Nav from '../components/nav'
import About from '../components/about'
import Featured from '../components/featured'
import {firebaseApp} from '../fireconfig.js';
import { getFirestore ,doc, getDoc } from "firebase/firestore"
import Experience from '../components/experience.js';
import Education from '../components/education.js';
import Projects from '../components/projects.js';

export default function Home() {

  const [featuredSnapShot, setFeaturedSnapShot] = React.useState([]);
  const projectRef = React.useRef(null);
  const expRef = React.useRef(null);
  const eduRef = React.useRef(null);

  React.useEffect(() => {
    async function getAboutdata(){
      const db = getFirestore(firebaseApp);

      // Assuming you have a document named "featured" within the "portfolio" collection
      const featuredRef = doc(db, "portfolio", "featured");
      const featureListSnapShot = await getDoc(featuredRef);
      setFeaturedSnapShot(featureListSnapShot.data().featuredList);
    }
    getAboutdata();
  },[])


  return (
    <div className='home'>
        {/* <Nav
          projectRef={projectRef}
          expRef={expRef}
          eduRef={eduRef}
        /> */}
        <About/>
        <Featured
          featuredListdata={featuredSnapShot}
        />
        <Experience
          expRef={expRef}
        />
        <Education
          eduRef={eduRef}
        />
        <Projects
          projectRef={projectRef}
        />
    </div>
  )
}
