import {initializeApp} from 'firebase/app';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCRndJk6b5AB2kPSeY4P7QNJHqeU6DRoQY",
  authDomain: "portfolio-31019.firebaseapp.com",
  projectId: "portfolio-31019",
  storageBucket: "portfolio-31019.appspot.com",
  messagingSenderId: "355003476849",
  appId: "1:355003476849:web:17eb8f37938ca96d33a4d0"
};

// Initialize Firebase
const firebaseApp  = initializeApp(firebaseConfig);

export { firebaseApp };