import React from 'react'
import {firebaseApp} from '../fireconfig.js';
import { getFirestore ,collection , getDocs} from "firebase/firestore"

export default function Education({eduRef}) {

    const [eduDocs, setEduDocs] = React.useState(null);

    React.useEffect(()=>{
        async function getEducationData(){
            const db = getFirestore(firebaseApp);
            
            const educationCollectionRef = collection(db, "education");
            const educationSnapShot = await getDocs(educationCollectionRef);

            const fieldName = 'Index';

            const sortedDocs = educationSnapShot.docs.sort((a, b) => {
                const docAValue = a.data()[fieldName];
                const docBValue = b.data()[fieldName];
              
                // Sort logic (ascending order by default)
                if (docAValue > docBValue) return -1;
                if (docAValue < docBValue) return 1;
                return 0;
            });

            setEduDocs(sortedDocs);
        
        }
        getEducationData();
    },[])
  return (
    <div className='education'>
        <div className='title_education' ref = {eduRef}>
            Education
        </div>
        <div className='education_list'>
            {
                eduDocs ? 
                eduDocs.map((eduDoc) =>(
                    <div className='indiv_education'>
                        <div className='schoolLogo'>
                            <img src={eduDoc.data().Logo} alt='Company Logo' style={{width : '5vw'}}/>
                        </div>
                        <div className='indiv_education_right'>
                            {eduDoc.data().School} <br/> 
                            <span className='indiv_education_right_span'>{eduDoc.data().Degree} in {eduDoc.data().Major} <br/><br/> {eduDoc.data().From} - {eduDoc.data().Till}</span>
                        </div>
                    </div>
                ))
                :
                null
            }
        </div>
            
    </div>
  )
}
