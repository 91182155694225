import React from 'react'
import {firebaseApp} from '../fireconfig.js';
import { getFirestore ,doc, getDoc } from "firebase/firestore"
import FeatureCard from './FeatureCard.js';


export default function Featured({featuredListdata}) {

    const [featureList, setFeaturelist] = React.useState([]);

    React.useEffect(()=>{
        async function getFeaturedProjectData(){
            const db = getFirestore(firebaseApp);
            // const portfolio = db.collection('portfolio').doc('about');
            var finalFeatureListWithData = []
            
            for(const id of featuredListdata){
                const project = doc(db, "projects", id);
                const projectSnapShot = await getDoc(project);
                finalFeatureListWithData.push(projectSnapShot.data())
            }
            setFeaturelist(finalFeatureListWithData);
            console.log(finalFeatureListWithData);

        }
        getFeaturedProjectData();
    },[featuredListdata])


  return (
    <div className='featured'>
        <div className='title_featured'>
            Featured
        </div>

        <div className='featureListPallet'>
            {featureList.map((item) => (
            <FeatureCard item={item}/>
            ))}
        </div>
    </div>
  )
}
