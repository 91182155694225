import React from 'react'
import {firebaseApp} from '../fireconfig.js';
import { getFirestore ,collection , getDocs} from "firebase/firestore"

export default function Experience({expRef}) {

    const [expDocs, setExpDocs] = React.useState(null);

    React.useEffect(()=>{
        async function getAboutdata(){
            const db = getFirestore(firebaseApp);
            const experienceCollectionRef = collection(db, "experience");
            const experienceSnapShot = await getDocs(experienceCollectionRef);

            const fieldName = 'Index';

            const sortedDocs = experienceSnapShot.docs.sort((a, b) => {
                const docAValue = a.data()[fieldName];
                const docBValue = b.data()[fieldName];
              
                // Sort logic (ascending order by default)
                if (docAValue > docBValue) return -1;
                if (docAValue < docBValue) return 1;
                return 0;
            });

            setExpDocs(sortedDocs);
        }
        getAboutdata();
    },[])
  return (
    <div className='experience'>
        <div className='title_experience' ref={expRef}>
            Experience
        </div>
        <div className='experience_container'>
        {
            expDocs ? 
            expDocs.map((expDoc) => (
                <div className='indiv_experience'>
                    <div className='companyLogo'>
                        <img src={expDoc.data().Logo} alt='Company Logo' style={{width : '10vh'}}/>
                    </div>
                    
                    <div className='company'> 
                        {expDoc.data().Company}
                    </div>
                    
                    <div className='company_role_duration'>
                        {expDoc.data().Role} | {expDoc.data().Duration['total']}
                        </div>
                    <div className='company_summary'>{expDoc.data().Summary}</div>
                </div>
            ))
            :
            null
        }
        </div>
    </div>
  )
}
