import React from 'react'

export default function FeatureCard({item}) {
    const monthNumber = {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
    };
  return (
    <div>
        <div className="e-card playing">
            <div className="image"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>

            <div className="infotop">
                <div className="featured_title">{item.Title}</div>
                <div className="featured_date">{monthNumber[item.Date.toDate().getMonth()+1]} {item.Date.toDate().getFullYear()}</div>
                <div className="featured_skills">
                    {item.Skills && item.Skills.map((skill) => (
                        <span key={skill} className="featured_skill">
                        {skill}
                        </span>
                    ))}
                </div>
                <div className="featured_final">
                        {item.Demo ? <span>View Demo <a href={item.Demo} target='_blank' style={{ color:'white'}}>HERE</a> </span> 
                        : 
                        <span>View Source Code <a href={item.Github} target='_blank' style={{ color:'white'}}>HERE</a> </span> 
                        }
                </div>
            </div>
                        
        </div>
        {/* <button class="btn-shine">
            <span>Read More</span>
        </button> */}
    </div>

  )
}



    
{/* <div className="card">
         <div>
             <div className='title_card'>
             </div>
             <div className='title_card'>
                 {monthNumber[item.Date.toDate().getMonth()+1]}
             </div>
         </div>
     </div> */}