import React from 'react'
import {firebaseApp} from '../fireconfig.js';
import { getFirestore ,doc, getDoc } from "firebase/firestore"
import { getStorage, ref, getDownloadURL  } from "firebase/storage";
import LinkedIn from '../assets/linkedin.png';
import Github from '../assets/github.png';

export default function About() {
    const [name, setName] = React.useState("test");
    const [role, setRole] = React.useState("test");
    const [obj, setObj] = React.useState("test");
    const [profilePic, setProfilePic] = React.useState(null);

    const linkedInClick = () => {
      // Redirect to the specified URL
      window.open("https://www.linkedin.com/in/varunpandey30300/", '_blank');
  };

  const githubClick = () => {
    // Redirect to the specified URL
    window.open("https://github.com/varun30300", '_blank');
};

    React.useEffect(()  => {

        async function getAboutdata(){
            const db = getFirestore(firebaseApp);
            const storage = getStorage(firebaseApp);
            // const portfolio = db.collection('portfolio').doc('about');
            const portfolio = doc(db, "portfolio", "about");
            const portfolioSnapShot = await getDoc(portfolio);
            setName(portfolioSnapShot.data().name)
            setRole(portfolioSnapShot.data().role)
            setObj(portfolioSnapShot.data().objective)

            // const profile = ref(storage, 'profilePic.jpg');
            getDownloadURL(ref(storage, 'profilePic.jpg'))
              .then((url) => {
                setProfilePic(url);
              });
        }
        getAboutdata();
    },[])

  return (
    <div className='about'>
      <div className='about_left'>
        <div className='name_about'>
          {name}
        </div>
        <div className='role_about'>
          {role}
        </div>
        <div className='obj_about'>
          {obj}
        </div>
        <div className='contacts'>
          <div>
            <img src={LinkedIn} alt='linkedIn' width={"25%"} onClick={linkedInClick} style={{cursor:"pointer"}}/>
          </div>
          <div>
          <img src={Github} alt='github' width={"25%"} onClick={githubClick} style={{cursor:"pointer"}}/>
          </div>
        </div>
      </div>
      <div className='about_right'>
        {
          profilePic ? <img src={profilePic} alt='profilePic' className='profilePic'/> :null
        }
      </div>
    </div>
  )
}
